import { Environment } from '@campaign-portal/components-library';

export const environment: Environment = {

	production: true,
	version: '1.4.0-0.sha.f04b879',

	protocol: '',
	host: '',
	port: '',
	api: 'api',
	localStorageKey: 'ap',
	configURL: `./assets/config.json?v=${(new Date()).getTime()}`,

	exchangeFolder: '/exchange/',

	title: 'Alaris Labs',
	subTitle: 'Admin Panel',

	theme: 'default',
	themeType: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',

	termsUrl: '',
	privacyUrl: '',
	supportEmail: '',

	showLink: true,
	allowRegistration: false,

	systemTZOffset: 0,
	localTime: false,

	languagesID: ['en', 'es', 'tr'],

	is2FA: false
};
